import React, {useContext} from "react";
import "./footer.scss"
import {Link} from "gatsby";
import Facebook from "../../assets/svg/icons-feather/facebook.svg"
import Instagram from "../../assets/svg/icons-feather/instagram.svg"
import LinkedIn from "../../assets/svg/icons-feather/linkedin.svg"
import {SiteContext} from "../../context/site-context";

const Footer = () => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;

  let privacyPolicyLink = `/${i18next.language}/privacy-policy`;
  let termsConditonsLink = `/${i18next.language}/terms-conditions`;
  let contactLink = `/${i18next.language}/contact`;

  return (
    <div className="footer">
      <ul className="menu">
        <li>© Copyright Quiet Quality 2019-{new Date().getFullYear()}</li>
        <li><Link to={privacyPolicyLink} activeClassName="active">Privacy policy</Link></li>
        <li><Link to={termsConditonsLink} activeClassName="active">Terms & conditions</Link></li>
        <li><Link to={contactLink} activeClassName="active">Contact</Link></li>
      </ul>
      <ul className="social">
        <li><a href="https://www.facebook.com/yourquietquality/" target="_blank" rel="noopener noreferrer"><p className="visually-hidden">Facebook</p><Facebook/><span className="hidden">Facebook</span></a></li>
        <li><a href="https://instagram.com/yourquietquality" target="_blank" rel="noopener noreferrer"><p className="visually-hidden">Instagram</p><Instagram/><span className="hidden">Instagram</span></a></li>
        <li><a href="https://linkedin.com/company/quiet-quality" target="_blank" rel="noopener noreferrer"><p className="visually-hidden">LinkedIn</p><LinkedIn/><span className="hidden">LinkedIn</span></a></li>
      </ul>
    </div>
  );
};

export default Footer;

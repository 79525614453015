import React, {useContext} from 'react'
// Runtime type checking for React props and similar objects.
import PropTypes from 'prop-types'

import Meta from '../meta';
import Header from '../header/header';
import "./layout.scss"
import Footer from "../navigation/footer";

const Layout = (props) => {

  const { entity, children, frontpage } = props;

  // Note: multiple react elements require wrapping <> fragment tag
  // https://reactjs.org/docs/fragments.html
  return (
    <>
    <Meta entity={entity} />
    <div className="layout">
      <Header isFrontpage={frontpage}/>
      <div className="content">
        {children}
      </div>
      <Footer/>
    </div>
    </>
  )
};

Layout.defaultProps = {
  entity: null,
  children: null,
};

Layout.propTypes = {
  entity: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default Layout;




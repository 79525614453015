import React, {useContext} from 'react';
import {Link} from "gatsby";
import "./navigation.scss"
import {SiteContext} from "../../context/site-context";
import Hamburger from "../../assets/svg/icons-feather/menu.svg"
import Close from "../../assets/svg/icons-feather/x.svg";
import Button from "../../components/buttons/button"

function Navigation() {

  const siteContext = useContext(SiteContext);

  if (siteContext.i18next.language === 'en') {
    return (
      <>
        <div className="menu-hamburger"><Button hamburgerOpen={true} callback={siteContext.menu.toggleMenu}><Hamburger/></Button></div>
        <div className={`navigation ${siteContext.menu.menuState}`}>
          <div className="menu-close"><Button hamburgerClosed={true} callback={siteContext.menu.toggleMenu}><Close/></Button></div>
          <ul>
            <li><Link to="/en" activeClassName="active" onClick={siteContext.menu.closeMenu}>Home</Link></li>
            <li><Link to="/en/about" activeClassName="active" onClick={siteContext.menu.closeMenu}>About me</Link></li>
            <li><Link to="/en/services" activeClassName="active" onClick={siteContext.menu.closeMenu}>Services</Link></li>
            <li><Link to="/en/blog" activeClassName="active" onClick={siteContext.menu.closeMenu}>Blog</Link></li>
            <li><Link to="/en/reviews" activeClassName="active" onClick={siteContext.menu.closeMenu}>Reviews</Link></li>
            <li><Link to="/en/contact" activeClassName="active" onClick={siteContext.menu.closeMenu}>Contact</Link></li>
            <li className="language-switch"><Link to="/nl" activeClassName="active" onClick={siteContext.menu.closeMenu}>EN</Link>
              <ul>
                <li><Link to="/en" className="language-switch" activeClassName="active" onClick={siteContext.menu.closeMenu}>English</Link></li>
                <li><Link to="/nl" className="language-switch" activeClassName="active" onClick={siteContext.menu.closeMenu}>Nederlands</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </>
    );
  }

  if (siteContext.i18next.language === 'nl') {
    return (
      <>
        <div className="menu-hamburger"><Button hamburgerOpen={true} callback={siteContext.menu.toggleMenu}><Hamburger/></Button></div>
        <div className={`navigation ${siteContext.menu.menuState}`}>
          <div className="menu-close"><Button hamburgerClosed={true} callback={siteContext.menu.toggleMenu}><Close/></Button></div>
          <ul>
            <li><Link to="/nl" activeClassName="active" onClick={siteContext.menu.closeMenu}>Home</Link></li>
            <li><Link to="/nl/about" activeClassName="active" onClick={siteContext.menu.closeMenu}>Over mij</Link></li>
            <li><Link to="/nl/diensten" activeClassName="active" onClick={siteContext.menu.closeMenu}>Diensten</Link></li>
            <li><Link to="/nl/blog" activeClassName="active" partiallyActive={true} onClick={siteContext.menu.closeMenu}>Blog</Link></li>
            <li><Link to="/nl/de-media" activeClassName="active" onClick={siteContext.menu.closeMenu}>In de media</Link></li>
            <li><Link to="/nl/reviews" activeClassName="active" onClick={siteContext.menu.closeMenu}>Reviews</Link></li>
            <li><Link to="/nl/contact" activeClassName="active" onClick={siteContext.menu.closeMenu}>Contact</Link></li>
            <li className="language-switch"><Link to="/en" activeClassName="active" onClick={siteContext.menu.closeMenu}>NL</Link>
              <ul>
                <li><Link to="/nl" className="language-switch" activeClassName="active" onClick={siteContext.menu.closeMenu}>Nederlands</Link></li>
                <li><Link to="/en" className="language-switch" activeClassName="active" onClick={siteContext.menu.closeMenu}>English</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </>
    );
  }

  return (
    <p>No navigation for this language.</p>
  );

}

export default Navigation;

import React, {useContext} from "react"
import Navigation from "../navigation/navigation";
import {Link} from "gatsby";
import "./header.scss"
import {SiteContext} from "../../context/site-context";

export default (props) => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;
  const frontpagePath = `/${i18next.language}`;
  const { isFrontpage } = props;

  return (
    <header className="header">

      <div className="left">
        { isFrontpage &&
          <h1 className="logo">Quiet Quality</h1>
        }
        { !isFrontpage &&
          <Link className="logo" to={frontpagePath}>Quiet Quality</Link>
        }
      </div>

      <div className="right">
        <Navigation />
      </div>

    </header>
  )
}

import React, {useContext} from "react"
import {Link} from "gatsby"
import "./button.scss"
import {SiteContext} from "../../context/site-context";

// A button which can be a button element with a callback or a link element if a link is provided.

export default (props) => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;

  // Merging class names from parent components with default.
  let classNames = ['button'];
  if (props.className) {
    props.className.split(' ').forEach(function (name) {
      classNames.push(name);
    });
  }
  classNames = classNames.join(' ');

  let ariaExpanded = (siteContext.menu.menuState !== 'closed');

  // Sometimes we just want a fake button.
  if (props.callback === undefined && props.link === undefined) {
    return (
      <>
        <button className={classNames}>{props.children}</button>
      </>
    )
  }

  if (props.callback) {
    if (props.hamburgerOpen) {
      return (
        <>
          <button aria-label={i18next.t('Collapse or expand the menu')} aria-haspopup="true" aria-expanded={ariaExpanded} className={classNames} onClick={props.callback}>{props.children}</button>
        </>
      )
    }
    if (props.hamburgerClosed) {
      return (
        <>
          <button aria-label={i18next.t('Close the menu')} className={classNames} onClick={props.callback}>{props.children}</button>
        </>
      )
    }
    return (
      <>
        <button className={classNames} onClick={props.callback}>{props.children}</button>
      </>
    )
  }

  if (props.link) {
    return (
      <>
        <Link className={classNames} to={props.link}>{props.children}</Link>
      </>
    )
  }

}

import React, {useContext} from "react"
import { useStaticQuery, graphql } from "gatsby"
// This reusable React component will manage all of your changes to the document head.
import Helmet from 'react-helmet'
import {SiteContext} from "../context/site-context";
import { globalHistory } from '@reach/router';

const Meta = (props) => {

  const siteContext = useContext(SiteContext);

  const data = useStaticQuery(graphql`
    query MetaQuery {
      site {
        siteMetadata {
          nl {
            title
            description
            author
            siteUrl
          },
          en {
            title
            description
            author
            siteUrl
          }
        }
      }
    }
  `);

  const lang = siteContext.i18next.language;
  const bodyClass=`menu-${siteContext.menu.menuState}`;
  const meta = [];

  // Some logic to determine if we are on the frontpage, where we do not use an entity.
  const frontpages = ['', '/nl', '/en'];
  const path = globalHistory.location.pathname.endsWith('/') ? globalHistory.location.pathname.slice(0, -1) : globalHistory.location.pathname
  const isFrontpage = frontpages.includes(path);

  const getMetaImage = () => {
    const metaImageUrl = props.entity?.fieldImage?.imageGatsbyFile?.publicURL ?? false;
    if (metaImageUrl) {
      return {
        url: `${data.site.siteMetadata[lang].siteUrl}${props.entity.fieldImage.imageGatsbyFile.childImageSharp.fixed.src}`,
        width: props.entity.fieldImage.imageGatsbyFile.childImageSharp.fixed.width,
        height: props.entity.fieldImage.imageGatsbyFile.childImageSharp.fixed.height,
      }
    }
    return null;
  };

  const getTitle = () => {
    return props.entity?.entityMetatags[1]?.value || props.entity?.entityLabel || data.site.siteMetadata[lang].title;
  }

  const getDescription = () => {
    return isFrontpage ? data.site.siteMetadata[lang].description : (props.entity?.entityMetatags[2]?.value ?? '');
  }

  const metaTitle = getTitle();
  const metaImage = getMetaImage();
  const metaDescription = getDescription();

  let canonical = [];
  if (path === '') {
    canonical = [
      {
        rel: `canonical`,
        href: data.site.siteMetadata[lang].siteUrl,
      },
    ];
  }

  // todo canonical
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: bodyClass
      }}
      title={metaTitle}
      link={canonical}
      meta={[
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: data.site.siteMetadata[lang].author,
        },
        {
          property: `og:type`,
          content: isFrontpage ? `website` : `article`,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata[lang].author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
      ]
        .concat(
          metaImage
            ? [
              {
                name: `image`,
                property: "og:image",
                content: metaImage.url,
              },
              {
                property: "og:image:width",
                content: metaImage.width,
              },
              {
                property: "og:image:height",
                content: metaImage.height,
              },
              {
                name: "twitter:card",
                content: "summary_large_image",
              },
            ]
            : [
              {
                name: "twitter:card",
                content: "summary",
              },
            ]
        )
        .concat(meta)
      }
    />
  )

};

Meta.defaultProps = {
  entity: null,
};

export default Meta;
